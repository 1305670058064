import React from 'react';
import styled from '@emotion/styled';
import { defineMessages } from 'react-intl';
import { KyruusFormattedMessage } from '@kyruus/intl';
import { Link } from 'react-router-dom';
import Banner from '@kyruus/banner';
import Cancel from '@kyruus/react-ikons/Cancel';
import _findIndex from 'lodash/findIndex';
import { buildMarkdownParser } from '../utils/buildMarkdownParser';
import SortMenu from '../shared/sort-menu';
import SearchSummary from '../shared/search-summary';
import {
  MobileViewResultWrapper,
  MobileViewResultContainer,
  MobileViewResultBtn,
  StyledButtonTopPanel,
  StyledFacetsFixedTop,
  StyledCloseButton
} from './styles';

const messages = defineMessages({
  found: {
    id: 'resultsheader.searchdescription.found',
    description: 'The number of providers returned for the search',
    defaultMessage: `{providerCount} {providerCount, plural,
      one {provider}
      other {providers}
    } found`
  },
  clearfilters: {
    id: 'resultsheader.clearfilters',
    description: 'Button text to clear all previously selected filters',
    defaultMessage: 'Clear All Filters'
  },
  viewresults: {
    id: 'resultsheader.viewresults',
    description: 'Button text to view filter results',
    defaultMessage: 'View Results'
  },
  close: {
    id: 'resultsheader.close',
    description: 'Button text to close filter results',
    defaultMessage: 'Close'
  },
  sortandfilter: {
    id: 'resultsheader.searchdescription.sortandfilter',
    description: 'Button text to sort results and apply filters',
    defaultMessage: 'Sort and Filter'
  }
});

function logClearFiltersThunk(log) {
  return () => {
    log('user_action.search_results.clear_filters', { facet_cleared: 'all' });
  };
}

function getClearFiltersSearch(getUpdatedSearch) {
  return getUpdatedSearch([
    { action: 'delete_key', key: 'display_location' },
    { action: 'delete_key', key: 'location' },
    { action: 'delete_key', key: 'distance' },
    { action: 'delete_key', key: 'filter' }
  ]);
}

const FACETS_OPEN = 'facets-open';

class StopScroll extends React.Component {
  componentDidMount() {
    const container = this.props.container || window.document.body;
    const classNames = container.className.split[' '] || [];
    classNames.push(FACETS_OPEN);
    container.className = classNames.join(' ');
  }

  componentWillUnmount() {
    const container = this.props.container || window.document.body;
    const classNames = container.className.split[' '] || [];
    const openIndex = _findIndex(classNames, FACETS_OPEN);
    container.className = classNames.splice(openIndex, 1);
  }

  render() {
    return null;
  }
}

function MobileFiltersHeader({
  searchSummary,
  getUpdatedSearch,
  log,
  toggleMobileFacets
}) {
  let clearLink = null;
  if (searchSummary.has_filters) {
    const logClearFilters = logClearFiltersThunk(log);
    const clearFiltersSearch = getClearFiltersSearch(getUpdatedSearch);
    clearLink = (
      <Link
        to={clearFiltersSearch}
        id="clear-filters-mobile"
        className="clear-filters"
        onClick={logClearFilters}
      >
        <KyruusFormattedMessage {...messages.clearfilters} />
      </Link>
    );
  }

  return (
    <React.Fragment>
      <MobileViewResultWrapper>
        <MobileViewResultContainer>
          <MobileViewResultBtn
            id="hide-filters-mobile"
            className="hide-filters button btn-m"
            onClick={toggleMobileFacets}
          >
            <KyruusFormattedMessage {...messages.viewresults} />
          </MobileViewResultBtn>
        </MobileViewResultContainer>
      </MobileViewResultWrapper>
      <StyledButtonTopPanel>
        <StopScroll />
        <StyledFacetsFixedTop data-testid="facets-back-fixed-top">
          {searchSummary.has_filters && clearLink}
          <StyledCloseButton
            mode="flat"
            onClick={(e) => {
              e.preventDefault();
              toggleMobileFacets();
            }}
          >
            <KyruusFormattedMessage {...messages.close} />
          </StyledCloseButton>
        </StyledFacetsFixedTop>
      </StyledButtonTopPanel>
    </React.Fragment>
  );
}

function MobileResultsHeader({ totalProviders, toggleMobileFacets }) {
  return (
    <div className="consumer-facets-mobile col-md-1 mb-l">
      <h3 className="d-inline-block">
        <span className="result-item" id="provider-count-mobile">
          <KyruusFormattedMessage
            {...messages.found}
            values={{
              providerCount: totalProviders
            }}
          />
        </span>
      </h3>
      <button
        onClick={toggleMobileFacets}
        id="show-filters-mobile"
        className="show-filters f-right hidden-print button btn-simple"
      >
        <KyruusFormattedMessage {...messages.sortandfilter} />
        &nbsp;»
      </button>
    </div>
  );
}

function ResultsHeader({
  totalProviders,
  searchSummary,
  sortOptions,
  getUpdatedSearch,
  log,
  isLoadingOverlayShown
}) {
  return (
    <div className="row hidden-xs hidden-sm mb-s">
      <div className="col-md-6">
        <SearchSummary
          totalProviders={totalProviders}
          searchSummary={searchSummary}
          getUpdatedSearch={getUpdatedSearch}
          log={log}
          isLoadingOverlayShown={isLoadingOverlayShown}
        />
      </div>
      <div className="col-md-6">
        <div className="f-right mb-s hidden-print" data-view="sort">
          <SortMenu
            searchSummary={searchSummary}
            sortOptions={sortOptions}
            getUpdatedSearch={getUpdatedSearch}
          />
        </div>
      </div>
    </div>
  );
}

/**
 * With markdown, we need display flex to avoid
 * extra padding with <p>
 */
const BannerContent = styled('div')`
  display: flex;
  flex-direction: column;
`;

/**
 * Render an emergency banner that handles markdown
 * @param {Object} props
 * @param {Object} bannerConfig config with markdown for rendering
 */
function EmergencyBannerMd({ bannerConfig, action1AriaLabel }) {
  const { markdown = [], enabled } = bannerConfig;
  if (!enabled) {
    return null;
  }
  const md = buildMarkdownParser();

  return (
    <div id="emergency-banner-md">
      <Banner
        type="alert"
        icon="!"
        action1Text={<Cancel />}
        action1="close"
        action1AriaLabel={action1AriaLabel}
      >
        <BannerContent
          dangerouslySetInnerHTML={{ __html: md.render(markdown.join('\n')) }}
        />
      </Banner>
    </div>
  );
}

function EmergencyBanner({ config, action1AriaLabel }) {
  const bannerConfig = config['emergency_disclaimer'];
  if (bannerConfig && bannerConfig.enabled) {
    const text = bannerConfig.text || '';
    return (
      <div id="emergency-banner">
        <Banner
          type="alert"
          icon="!"
          action1Text={<Cancel />}
          action1="close"
          action1AriaLabel={action1AriaLabel}
        >
          {text}
        </Banner>
      </div>
    );
  } else {
    return null;
  }
}

export {
  MobileFiltersHeader,
  MobileResultsHeader,
  EmergencyBanner,
  EmergencyBannerMd
};
export default ResultsHeader;
