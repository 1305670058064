import React from 'react';
import { defineMessages, injectIntl } from 'react-intl';
import { KyruusFormattedMessage, kyruusFormatMessage } from '@kyruus/intl';

import { withRouter } from 'react-router-dom';
import _filter from 'lodash/filter';
import _startCase from 'lodash/startCase';

import LocationFacet from './location-facet';
import BasicFacet from './basic-facet';
import TypeaheadFacet from './typeahead-facet';
import { ClearFilterLink, StyledLegend, FacetWrapper } from './styles';

const messages = defineMessages({
  clear: {
    id: 'facet.clearall',
    description: 'Text to clear all applied filters on the facet',
    defaultMessage: 'Clear'
  },
  clearallAriaLabel: {
    id: 'facet.clearallAriaLabel',
    description: 'Text to clear all applied filters on the facet',
    defaultMessage: 'Clear All {facetName} filters'
  },
  searchFilterFormDescription: {
    id: 'facet.form.description',
    description: 'Accessibility description for the search filter form.',
    defaultMessage:
      'Changing an input in this form will immediately trigger a new search and subsequent change of context.'
  }
});

function ResetFilterLink({
  config,
  facetName,
  searchableLocation,
  log,
  getUpdatedSearch,
  labelName,
  intl
}) {
  let modifications = [];
  if (config.type === 'location') {
    modifications.push({ action: 'delete_key', key: 'distance' });
    if (searchableLocation) {
      modifications.push({ action: 'delete_key', key: 'location' });
      modifications.push({ action: 'delete_key', key: 'display_location' });
    }
  } else {
    modifications = (_filter(config.terms, 'applied') || []).map(
      (appliedFilter) => {
        return {
          action: 'delete_key_value',
          key: 'filter',
          value: appliedFilter.filter_param
        };
      }
    );
  }
  let clearedSearch = getUpdatedSearch(modifications);

  let logClearAllParams = () => {
    log('user_action.search_results.clear_filters', {
      facet_cleared: config.field
    });
  };

  return (
    <ClearFilterLink
      to={clearedSearch}
      onClick={logClearAllParams}
      id={'clear-' + facetName}
      data-testid={'clear-' + facetName}
      aria-label={kyruusFormatMessage(intl, messages.clearallAriaLabel, {
        facetName: kyruusFormatMessage(intl, labelName)
      })}
    >
      <KyruusFormattedMessage {...messages.clear} />
    </ClearFilterLink>
  );
}

const BaseFacetContainer = injectIntl(
  ({
    searchSummary,
    config,
    customerConfig,
    distanceSort,
    facetName,
    getUpdatedSearch,
    history,
    log,
    intl
  }) => {
    let addFilter = function (filter) {
      var params = [{ action: 'append', key: 'filter', value: filter }];
      history.push(getUpdatedSearch(params));
    };
    let removeFilter = function (filter) {
      history.push(
        getUpdatedSearch([
          { action: 'delete_key_value', key: 'filter', value: filter }
        ])
      );
    };
    const searchableLocation =
      config.allow_location_change !== false || !searchSummary.location;

    let facetLabelMessageDescriptor = {
      id: `field.name.${config.field}`,
      defaultMessage: _startCase(config.field),
      description: 'The name displayed above the facet'
    };

    let facet = {};
    switch (config.type) {
      case 'location':
        facet = (
          <LocationFacet
            searchSummary={searchSummary}
            config={config}
            customerConfig={customerConfig}
            distanceSort={distanceSort}
            searchableLocation={searchableLocation}
            getUpdatedSearch={getUpdatedSearch}
            log={log}
          />
        );
        break;
      case 'typeahead':
        facet = (
          <TypeaheadFacet
            config={config}
            facetName={facetName}
            facetLabelMessageDescriptor={facetLabelMessageDescriptor}
            addFilter={addFilter}
            removeFilter={removeFilter}
          />
        );
        break;
      default:
        facet = (
          <BasicFacet
            config={config}
            facetName={facetName}
            addFilter={addFilter}
            removeFilter={removeFilter}
            log={log}
            searchSummary={searchSummary}
            labelName={facetLabelMessageDescriptor}
            intl={intl}
          />
        );
    }

    return (
      <fieldset className="filter-group">
        <StyledLegend>
          <KyruusFormattedMessage {...facetLabelMessageDescriptor} />
        </StyledLegend>
        <FacetWrapper>{facet}</FacetWrapper>
        <ResetFilterLink
          config={config}
          facetName={facetName}
          searchableLocation={searchableLocation}
          log={log}
          getUpdatedSearch={getUpdatedSearch}
          labelName={facetLabelMessageDescriptor}
          intl={intl}
        />
      </fieldset>
    );
  }
);

const FacetContainer = withRouter(BaseFacetContainer);

function Facets({
  searchSummary,
  facets,
  config,
  getUpdatedSearch,
  distanceSort,
  log,
  showLocationFacet
}) {
  var facet_list = facets.map((facetConfig) => {
    if (facetConfig.field === 'location' && !showLocationFacet) return null;
    if (facetConfig.field === 'locations.primary_marketable_location_id') {
      return null;
    }
    return (facetConfig.field === 'location' &&
      facetConfig.distance_options.length) ||
      (facetConfig.terms && facetConfig.terms.length) ? (
      <FacetContainer
        searchSummary={searchSummary}
        key={facetConfig.field}
        facetName={facetConfig.field}
        customerConfig={config}
        config={facetConfig}
        distanceSort={distanceSort}
        getUpdatedSearch={getUpdatedSearch}
        log={log}
      />
    ) : null;
  });

  return (
    <form
      id="searchFilters"
      name="searchFilters"
      onSubmit={(e) => e.preventDefault()}
      aria-describedby="searchFilterFormDescription"
    >
      <KyruusFormattedMessage {...messages.searchFilterFormDescription}>
        {(msg) => (
          <span id="searchFilterFormDescription" className="sr-only">
            {msg}
          </span>
        )}
      </KyruusFormattedMessage>
      {facet_list}
    </form>
  );
}

export { Facets, FacetContainer };
export default Facets;
